import React from 'react'

export default function TestimonialCard({ info: { leadingSentence, paragraph: { paragraph }, testimonialFullname } }) {
    return (
        <div className="flex flex-col">
            <p className="font-semibold mt-6 text-blue-900 italic">{leadingSentence}</p>
            <p className="text-sm py-2 text-blue-800 italic">{paragraph}</p>
            <div className="text-xs pt-4 text-blue-900 mt-auto">{testimonialFullname}</div>
        </div>
    )
}
