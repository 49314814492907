import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ProcessCard from './ProcessCard'


export default function ProcessBlock() {
  const data = useStaticQuery(graphql`
    query {
        contentfulPlanningBlock {
          title
          leadingParagraph {
            leadingParagraph
          }
          processImage {
            file {
                url
            }
          }
          secondaryTitle
          processCards {
            processImage {
                file {
                  url
                }
              }
            imageSubtitle
            processParagraph {
              processParagraph
            }
          }
        }
      }
    `)

  const { title, leadingParagraph: { leadingParagraph }, processImage: { file: { url } }, secondaryTitle, processCards } = data.contentfulPlanningBlock
  return (
    <>
      <section>
        <div className="grid sm:grid-cols-2">
          <div className="flex flex-col justify-center items-center bg-gray-200">
            <h2 className="text-4xl font-bold text-blue-800">{title}</h2>
          </div>
          <div className="flex flex-col items-center pt-10">
            <img className="sm:max-w-20 sm:max-h-40 sm:h-40 h-20" src={url} alt="Houses Connected" />
            <p className="w-3/4 py-4 text-sm text-center">{leadingParagraph}</p>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="flex flex-col text-justify items-center">
          <h2 className="text-4xl text-blue-800 py-6 font-semibold">{secondaryTitle}</h2>
        </div>
        <div className="grid sm:grid-cols-2 gap-10 px-10">
          {processCards.map((card, index) => {
            return <ProcessCard key={index} card={card}></ProcessCard>
          })}
        </div>
        <div className="flex flex-col items-center py-10">
          <Link to="/planning">
            <button className="btn btn-blue">Learn more about planning</button>
          </Link>
        </div>
      </section>
    </>
  )
}
