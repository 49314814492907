import React from "react"
import Layout from "../components/Layout"
import SEO from '../components/SEO'
import HomeHero from "../components/Home/HomeHero"
import ServicesSection from "../components/Home/ServicesSection"
import ProcessBlock from "../components/Home/ProcessBlock"
import Testimonials from "../components/Testimonials"
import Contactbanner from "../components/ContactBanner"

export default () => {
  return (
    <Layout>
      <SEO title="Home" />
      <HomeHero />
      <ServicesSection />
      <ProcessBlock />
      <Testimonials />
      <Contactbanner />
    </Layout>
  )
}
