import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import useSanitize from "../../hooks/useSanitize"


export default function HomeHero() {
    const data = useStaticQuery(graphql`
        query {
            contentfulHero {
              heroImage {
                fluid {
                    ...GatsbyContentfulFluid
                }
              }
              leadingText
              buttonText
            }
            markdownRemark {
                html
              }
          }  
    `)

    const { heroImage, leadingText, buttonText } = data.contentfulHero
    const paragraph = useSanitize(data.markdownRemark.html)

    function displayMarkdown() {
        return { __html: paragraph }
    }

    return (
        <section className="w-100%">
            <div>
                <BackgroundImage className="w-full min-h-full object-cover bg-center bg-no-repeat py-40" fluid={heroImage[0].fluid}>
                    <div className="p-6">
                        <div className="flex flex-col flex-wrap xl:w-1/2 sm:py-10 sm:px-12 bg-gray-200 bg-opacity-50 rounded md:w-100%">
                            <h1 className="font-extrabold md:text-5xl text-xl text-blue-900">{leadingText}</h1>
                            <div className="pt-2 md:text-sm text-xs font-semibold text-blue-800" dangerouslySetInnerHTML={displayMarkdown()}></div>
                            <Link className="flex justify-end py-4" to="/contact">
                            <button className="btn btn-blue">
                                {buttonText}
                            </button>
                        </Link>
                        </div>
                        
                    </div>
                    
                </BackgroundImage>

            </div>

        </section>
    )
}
