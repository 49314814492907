import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import TestimonialCard from './TestimonialCard'

export default function Testimonials() {
    const data = useStaticQuery(graphql`
    query getTestimonials {
        contentfulTestimonials {
          title
          testimonialCard {
            leadingSentence
            paragraph {
              paragraph
            }
            testimonialFullname
          }
        }
      }
      
    `)

    const { title, testimonialCard } = data.contentfulTestimonials
    return (
        <section className="bg-gray-200 pb-10 text-center">
            <div className="flex flex-col justify-center items-center"><h2 className="text-4xl font-bold text-blue-800 py-6">{title}</h2></div>
            <div className={`${testimonialCard.length >= 3 ? `sm:grid-cols-3` : `sm:grid-cols-2`} grid gap-10 px-10`}>
                {testimonialCard.map((item, index) => {
                    return <TestimonialCard key={index} info={item} />
                })}
            </div>
        </section>
    )
}
