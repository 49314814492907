import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'


const ServicesSection = () => {
  const data = useStaticQuery(graphql`
    query getServiceSection {
        contentfulCompanyDescription {
          title
          leadingParagraph {
            leadingParagraph
          }
          servicesImage {
            file {
              url
            }
          }
          listLeft
          listRight
        }
      }
    `)

  const { title, listLeft, listRight, leadingParagraph: { leadingParagraph }, servicesImage: { file: { url } } } = data.contentfulCompanyDescription


  function listServices(service) {
    return service.map(item => <li key={item}>{item}</li>)
  }
  return (
    <section className="flex flex-col items-center bg-gradient-to-l from-blue-800 to-blue-900 text-gray-100">
      <h2 className="py-8 text-4xl font-extrabold text-blue-100">{title}</h2>
      <p className="p-4 max-w-xl text-center text-blue-100">{leadingParagraph}</p>
      <img className="h-16 mb-6" src={url} alt="Three houses next to eachother. Ascending in height." />

      <div className="grid sm:grid-cols-2 mb-10 font-semibold gap-12 px-4 text-center text-blue-200">
        <div className="left">
          <ul>
            {listServices(listLeft)}
          </ul>
        </div>
        <div className="right">
          <ul>
          {listServices(listRight)}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default ServicesSection