import React from 'react'

export default function ProcessCard({ card }) {
    const { imageSubtitle, processParagraph: { processParagraph }, processImage: { file: { url } } } = card
    return (
        <div className="flex flex-col items-center">
             <img className="sm:max-w-10 sm:max-h-10 h-24 w-24" src={url} alt="The Processes" />
            <span className="text-blue-800 font-bold text-xl mt-4 pb-4">{imageSubtitle}</span>
            <p className="px-10 text-center text-gray-800">
                {processParagraph}
                </p>
        </div>
    )
}
